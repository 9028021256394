//BIENESTAR

let pr1ZTBienestar = {
    room: "ztBienestar",
    title: "Cama ECO",
    price: "Consultar precio",
    description: "Cama de FMB, apropiada para usuarios válidos y asistidos. Además de las articulaciones para respaldo y rodillas, dispone de CARRO ELEVADOR, que ayuda a la salida y entrada del usuario de la cama, además al cuidador a la tarea de hacer la cama, y adaptar la altura sin necesidad de forzar postura. También ayuda para la consulta de enfermería. Se le pueden añadir potencia y portasueros.",
    storeLink: "https://ortopedia.kaldevi.com/camas-articuladas/",
    srcModel: "/TV_bien_1.glb",
    images: [
        {
            src: "/bien1/1.webp",
            description: "Formidabel ECO con protecciones laterales.",
        },
        {
            src: "/bien1/2.webp",
            description: "La cama ECO dispone de diferentes articulaciones, así como de carro elevador para ayudar al máximo bienestar sin forzar la postura.",
        },
        {
            src: "/bien1/3.gif",
            description: "Con un diseño versátil, permite diferentes posiciones de las protecciones laterales.",
        },
        {
            src: "/bien1/4.gif",
            description: "También dispone de un accesorio para proteger los remates alrededor de las ruedas.",
        },
        {
            src: "/bien1/5.webp",
            description: "También dispone de un accesorio para proteger los remates alrededor de las ruedas.",
        },
    ],
    relatedProducts: [
        {
            src: "/related/bien1_1.webp",
            title: "Cama FORMIDABLE",
            link: "https://kaldevi.com/producto/cama-geriatrica-formidabel/",
        },
        {
            src: "/related/bien1_2.webp",
            title: "Cama BERIT",
            link: "https://ortopedia.kaldevi.com/producto/cama-geriatrica-berit/",
        },
        {
            src: "/related/bien1_3.webp",
            title: "Cama GESEKO",
            link: "https://ortopedia.kaldevi.com/producto/cama-geriatrica-geseko/",
        },
    ]
}

let pr2ZTBienestar = {
    room: "ztBienestar",
    title: "Sillón Cocoon",
    price: "Consultar precio",
    description: "El sillón COCOON de Innovsa es muy cómodo y de múltiples posiciones. Se puede usar tanto en el hogar como en los centros sociosanitarios, debido a su revestimiento, fácil de limpiar.",
    storeLink: "https://ortopedia.kaldevi.com/producto/sillon-relax-cocoon/",
    srcModel: "/TV_bien_2.glb",
    images: [
        {
            src: "/bien2/1.jpg",
            description: "El diseño de la gama Cocoon de Innovsa contempla múltiples posiciones, siempre buscando el máximo confort.",
        },
        {
            src: "/bien2/2.jpg",
            description: "El diseño de la gama Cocoon de Innovsa contempla múltiples posiciones, siempre buscando el máximo confort.",
        },
        {
            src: "/bien2/3.jpg",
            description: "El revestimiento, disponible en una selección de colores, es muy fácil de limpiar.",
        },
        {
            src: "/bien2/4.jpg",
            description: "El revestimiento, disponible en una selección de colores, es muy fácil de limpiar.",
        },
        {
            src: "/bien2/5.jpg",
            description: "El diseño de la gama Cocoon de Innovsa contempla múltiples posiciones, siempre buscando el máximo confort.",
        },
    ],
    relatedProducts: [
        {
            src: "/related/bien2_1.webp",
            title: "Sillón Elisabeth",
            link: "https://ortopedia.kaldevi.com/producto/sillon-relax-elisabeth/",
        },
        {
            src: "/related/bien2_2.webp",
            title: "Sillón Elysee",
            link: "https://ortopedia.kaldevi.com/producto/sillon-relax-elysee/",
        },
        {
            src: "/related/bien2_3.webp",
            title: "Balance Totalcare",
            link: "https://ortopedia.kaldevi.com/producto/silla-de-ruedas-basculante-balance/",
        },
    ]
}

let pr3ZTBienestar = {
    room: "ztBienestar",
    title: "Electroestimulador Genesy 1500",
    price: "Consultar precio",
    description: "Electroestimulador Globus Genesy 1500 de 4 Canales Profesional, de Global. Genesy 1500 es extraordinariamente potente y seguro de utilizar en cualquier situación, además está equipado con todas las corrientes utilizadas en los campos médicos, rehabilitación y deportivo. Contiene un conjunto completo de programas para cada tipo de necesidad y todos los tipos de corrientes más comunes. 414 Programas.",
    storeLink: "https://kaldevi.com/producto/aparato-electroestimulacion-genesy-3000-rehab/",
    srcModel: "/TV_bien_3.glb",
    images: [
        {
            src: "/bien3/1.webp",
            description: "Genesy 1500 es ideal para su uso en el ámbito médico y rehabilitación e incluye todas las corrientes usadas para rehabilitación, fisioterapia y re-educación neuromotora.",
        },
        {
            src: "/bien3/2.webp",
            description: "La electroestimulación es una técnica que utiliza pequeños impulsos eléctricos para actuar en los puntos motores del músculo o en las terminaciones nerviosas, causando una contracción muscular similar a una contracción voluntaria.",
        },
        {
            src: "/bien3/3.webp",
            description: "Deporte. La electroestimulación genera una contracción muscular muy fuerte, que produce extraordinarios efectos de entrenamiento que aumentan rápidamente la calidad muscular y el rendimiento de los atletas.",
        },
        {
            src: "/bien3/4.webp",
            description: "Estética. Globus te ayuda a alcanzar toda la forma física y estética que siempre has deseado. La electroestimulación activa los músculos, mejora la circulación, ayuda al drenaje linfático y te da una nueva vitalidad y atractivo.",
        },
        {
            src: "/bien3/5.webp",
            description: "Salud. Globus te ayuda a recuperar la salud, el bienestar y una nueva oportunidad de vida gracias a sus corrientes pulsadas Tens que activan los mecanismos analgésicos naturales de tu cuerpo y eliminan el dolor.",
        },
        
    ],
    relatedProducts: [
        {
            src: "/related/bien3_1.webp",
            title: "Magnetoterapia Globus",
            link: "https://ortopedia.kaldevi.com/",
        },
        {
            src: "/related/bien3_2.webp",
            title: "Bandas elásticas",
            link: "https://ortopedia.kaldevi.com/",
        },
        {
            src: "/related/bien3_3.webp",
            title: "Pedallier",
            link: "https://ortopedia.kaldevi.com/",
        },
    ]
}


let pr4ZTBienestar = {
    room: "ztBienestar",
    title: "Silla de baño Clean",
    price: "312,00 €",
    description: "La silla de ducha de Karinter es cómoda, segura y de fácil de manejo. Por su calidad, resistencia y fiabilidad, se recomienda para un uso intensivo y frecuente. Con 4 ruedas, 2 de ellas con freno y apertura del asiento por detrás, garantiza la máxima intimidad para el cuidador y el usuario.",
    storeLink: "https://ortopedia.kaldevi.com/producto/silla-de-ducha-clean/",
    srcModel: "/TV_bien_4.glb",
    images: [
        {
            src: "/bien4/1.webp",
            description: "La silla Clean encaja perfectamente sobre el wc estándar.",
        },
        {
            src: "/bien4/2.webp",
            description: "La apertura del asiento por detrás, garantiza la máxima intimidad para el cuidador y el usuario.",
        },
        {
            src: "/bien4/3.webp",
            description: "Nacida en los 90, su diseño de futuro la ha convertido en la silla móvil de ducha más vendida.",
        },
        {
            src: "/bien4/4.webp",
            description: "La silla está disponible en dos alturas: 49 o 55 cms, y en dos colores: blanco y verde.",
        },
        {
            src: "/bien4/5.jpg",
            description: "El diseño inteligente de Clean permite un área de asiento amplia y cómoda, a la vez que mantiene la silla fina y elegante.",
        },
    ],
    relatedProducts: [
        {
            src: "/related/bien4_1.webp",
            title: "Asidero",
            link: "https://kaldevi.com/asideros/",
        },
        {
            src: "/related/bien4_2.webp",
            title: "Alza",
            link: "https://ortopedia.kaldevi.com/",
        },
        {
            src: "/related/bien4_3.webp",
            title: "Taburete",
            link: "https://ortopedia.kaldevi.com/",
        },
    ]
}

let productData = [pr1ZTBienestar, pr2ZTBienestar, pr3ZTBienestar, pr4ZTBienestar]

export default productData;